import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const IndexPage = () => (
  <Layout>
    <SEO title="Teilnahmebedingungen"/>
    <div className="top-area-bg">
      <div className="container">
        <div className="top-area-text-container">
          <h2 className="top-area-text-title">Teilnahmebedingungen – Schöffel Danke Momente</h2>
        </div>
      </div>
    </div>
    <div className="content-area">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h2>
              Teilnahmebedingungen
            </h2>
            <p>Die Teilnahme an der Aktion von Schöffel Sportbekleidung GmbH, Ludwig-Schöffel-Straße 15, 86830 Schwabmünchen, nachfolgend Veranstalter genannt, ist kostenlos und richtet sich ausschließlich nach diesen Teilnahmebedingungen.
              <br/><br/>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h2>
              Teilnahmeberechtigte
            </h2>
            <p>Teilnahmeberechtigt sind natürliche Personen mit Wohnsitz in Deutschland, Österreich und Schweiz, die das 18. Lebensjahr vollendet haben. Die Teilnahme ist nicht vom Erwerb einer Ware oder Dienstleistung abhängig.<br/>
              Nicht teilnahmeberechtigt an der Aktion sind die Mitarbeiter der Schöffel Sportbekleidung GmbH und der mit ihr verbundenen Unternehmen. Schöffel Sportbekleidung GmbH behält sich vor, Personen von der Teilnahme auszuschließen, wenn berechtigte Gründe vorliegen, beispielsweise
            </p>
            <ol type="a">
              <li>bei Manipulationen im Zusammenhang mit der Durchführung der Aktion,</li>
              <li>bei Verstößen gegen diese Teilnahmebedingungen,</li>
              <li>bei falschen oder irreführenden Angaben im Zusammenhang mit der Teilnahme an der Aktion.</li>
              <li>Beiträge, die verleumderische, obszöne, anstößige, rechtswidrige oder sonst unangemessene Inhalte aufweisen, werden aus der Aktion ausgeschlossen und der Teilnehmer disqualifiziert.</li>
            </ol>
            <br/><br/>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h2>
              Teilnahme
            </h2>
            <p>Der Wettbewerb findet vom 13.05.2020, 12.00 Uhr, bis einschließlich 28.06.2020, 22.00 Uhr, auf Schoeffel.com statt.<br/>
              Um teilzunehmen, muss jeder Teilnehmer zunächst auf <a href="https://danke.schoeffel.com/" className="link-text">danke.schoeffel.com</a> gehen, einen der Schöffel Danke Momente Preise auswählen und das auf <a href="https://danke.schoeffel.com/" className="link-text">danke.schoeffel.com</a> veröffentlichte Teilnahmeformular ausfüllen und absenden.<br/>
              Der Teilnehmer kann seine Teilnahme jederzeit widerrufen, indem er den Veranstalter unter mail (ät) schoeffel.com darüber informiert.
              <br/><br/>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h2>
              Gewinn, Benachrichtigung und Übermittlung des Gewinns
            </h2>
            <p>Unter allen Teilnehmern werden die auf <a href="https://danke.schoeffel.com/" className="link-text">danke.schoeffel.com</a> beschriebenen und dargestellten Danke Momente ausgeschüttet, wofür Teilnehmer ausgewählt werden.<br/>
              Der bzw. die Gewinner der Aktion werden vom Veranstalter innerhalb von einer Woche nach Teilnahmeschluss per E-Mail benachrichtigt. Im Falle einer unzustellbaren Benachrichtigung ist der Veranstalter nicht verpflichtet, weitere Nachforschungen anzustellen und kann einen neuen Gewinner ermitteln. Meldet sich der Gewinner nicht innerhalb einer Woche nach der Gewinnbenachrichtigung, ist der Veranstalter gleichfalls berechtigt, einen neuen Gewinner zu ermitteln.<br/>
              Die Aushändigung des Gewinns erfolgt ausschließlich an den Gewinner. Ein Umtausch, eine Selbstabholung sowie eine Barauszahlung des Gewinns sind nicht möglich. Sachpreise werden dem Gewinner per Post an seine Wohnanschrift zugesendet, die Versandkosten hierfür übernimmt der Veranstalter. Für eine etwaige Versteuerung des Gewinns ist der Gewinner selbst verantwortlich.
              <br/><br/>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h2>
              Beendigung der Aktion
            </h2>
            <p>Der Veranstalter behält sich ausdrücklich vor, die Aktion ohne vorherige Ankündigung und ohne Mitteilung von Gründen jederzeit zu unterbrechen oder zu beenden und diese Teilnahmebedingungen anzupassen.
              <br/><br/>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h2>
              Datenschutz
            </h2>
            <p>Für die Teilnahme an der Aktion ist die Angabe von persönlichen (personenbezogenen) Daten notwendig. Der Teilnehmer versichert, dass die von ihm gemachten Angaben zur Person, insbesondere Vor-, Nachname und E-Mailadresse wahrheitsgemäß und richtig sind. Bei Sachpreisen werden nach Ende der Aktion von den Gewinnern im Rahmen einer Benachrichtigung weitere persönliche Daten, wie etwa die Anschrift, abgefragt. Die Erfassung und Verarbeitung der personenbezogenen Daten dient ausschließlich zur Durchführung der Aktion und gegebenenfalls zur Zusendung der Gewinne. Sämtliche Daten werden nur für den Zweck und für die Dauer der Aktion gespeichert und nach Ablauf der Aktion gelöscht, sofern keine gesetzlichen Aufbewahrungspflichten bestehen.<br/>
              Die personenbezogenen Daten der Teilnehmer werden ohne Einverständnis weder an Dritte weitergegeben noch diesen zur Nutzung überlassen. Eine Ausnahme stellt das für die Durchführung der Aktion, insbesondere die Versendung des Gewinns, in Auftrag durch die Schöffel Sportbekleidung GmbH, Ludwig-Schöffel-Str. 15, 86830 Schwabmünchen, welches die Daten zum Zwecke der Durchführung der Aktion erhebt, speichert und nutzt.<br/>
              Im Falle eines Gewinns, erklärt sich der Gewinner mit der Veröffentlichung seines Namens und Wohnorts in den vom Veranstalter genutzten Werbemedien einverstanden. Dies schließt die Bekanntgabe des Gewinners auf der Webseite des Veranstalters und seinen Social Media Plattformen ein.<br/>
              Der Teilnehmer kann zu jeder Zeit Auskunft über seine beim Veranstalter gespeicherten Daten erhalten, der Nutzung seiner Daten widersprechen und deren Löschung verlangen. Hierzu genügt ein einfaches Schreiben an  Schöffel Sportbekleidung GmbH, Ludwig-Schöffel-Str. 15, 86830 Schwabmünchen. Nach Widerruf der Einwilligung werden die erhobenen und gespeicherten personenbezogenen Daten des Teilnehmers umgehend gelöscht.<br/>
              Weitere Regelungen zum Umgang mit personenbezogenen Daten können der Datenschutzerklärung des Veranstalters unter <a href="https://www.schoeffel.com/de/de/datenschutz" className="link-text">https://www.schoeffel.com/de/de/datenschutz</a> entnommen werden.
              <br/><br/>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h2>
              Facebook Disclaimer
            </h2>
            <p>Die Aktion steht in keiner Verbindung zu Facebook und wird in keiner Weise von Facebook gesponsert, unterstützt oder organisiert. Ansprechpartner und Verantwortlicher ist allein der Veranstalter.
              <br/><br/>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h2>
              Haftung
            </h2>
            <p>Der Veranstalter haftet nicht für Schäden, die durch Fehler, Verzögerungen oder Unterbrechungen in der Übermittlung, bei Störungen der technischen Anlagen und des Services, unrichtige Inhalte, Verlust oder Löschung von Daten, Viren oder in sonstiger Weise bei der Teilnahme an der Aktion entstanden sind, es sei denn, dass solche Schäden vom Veranstalter (seinen Organen, Mitarbeitern oder Erfüllungsgehilfen) vorsätzlich oder grob fahrlässig herbeigeführt worden sind. Der Veranstalter ist stets bemüht, in technisch einwandfreier Qualität zu veröffentlichen und korrekte Aussagen zu machen. Der Veranstalter haftet jedoch nicht für Fehlaussagen (z.B. zum Gewinn) oder technisch bedingter Fehler im Zusammenhang mit der Gewinnentscheidung. Vorstehende Haftungsbeschränkung gilt nicht bei der Verletzung von Leben, Körper und Gesundheit sowie bei arglistig verschwiegenen Mängeln.
              <br/><br/>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h2>
              Anwendbares Recht
            </h2>
            <p>Die Aktion unterliegt ausschließlich dem Recht der Bundesrepublik Deutschland. Der Rechtsweg ist ausgeschlossen.
              <br/><br/>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h2>
              Salvatorische Klausel
            </h2>
            <p>Sollte eine Bestimmung dieser Teilnahmebedingungen ganz oder teilweise unwirksam sein oder werden, so wird dadurch die Gültigkeit dieser Teilnahmebedingungen im Übrigen nicht berührt. Statt der unwirksamen Bestimmung gilt diejenige gesetzlich zulässige Regelung, die dem in der unwirksamen Bestimmung zum Ausdruck gekommenen Sinn und Zweck wirtschaftlich am nächsten kommt. Entsprechendes gilt für den Fall des Vorliegens einer Regelungslücke in diesen Teilnahmebedingungen.
              <br/><br/>
            </p>
          </div>
        </div>
        <Link to="/" className="btn btn-primary">Zurück zum Gewinnspiel</Link>
      </div>
    </div>
  </Layout>
)

export default IndexPage
